<template>
  <div>
    <b-overlay
      :show="isLoading"
      rounded
      opacity="0.6"
      spinner-variant="primary"
      spinner-mediam
      >
        <b-row>
          <b-col class="text-right">
            <b-button
              to="/role"
              size="sm"
              variant="success"
              class="mb-1"
            >
              <feather-icon icon="PlusSquareIcon" />
              {{$t('Roles')}}
            </b-button>
          </b-col>
        </b-row>
        <template v-if="Object.keys(owner).length > 0">
          <transition
          mode="out-in"
          >
          <b-row>
            <b-col md="6" cols="12">
              <b-card no-body>
                <b-card-header>
                  <b-card-title>{{$t('Store Owner')}}</b-card-title>
                  <b-button
                    variant="outline-primary"
                    size="sm"
                    @click="$bvModal.show('ownerModal')"
                  >
                    <feather-icon
                      icon="EditIcon"
                      size="13"
                      class="cursor-pointer"
                    />
                  </b-button>
                </b-card-header>
                <b-card-body>
                  <dl class="row">
                    <dt class="col-sm-4 font-weight-bolder">
                      {{$t('Name')}}
                    </dt>
                    <dd class="col-sm-8">
                      {{owner.name}}
                    </dd>
                    <dt class="col-sm-4 font-weight-bolder">
                      {{$t('Mobile')}}
                    </dt>
                    <dd class="col-sm-8">
                      {{owner.mobile}}
                    </dd>
                    <dt class="col-sm-4 font-weight-bolder">
                      {{$t('Email')}}
                    </dt>
                    <dd class="col-sm-8">
                      {{owner.email}}
                    </dd>
                    <dt class="col-sm-4 font-weight-bolder">
                      {{$t('Role')}}
                    </dt>
                    <dd class="col-sm-8">
                      {{$t('Owner')}}
                    </dd>
                  </dl>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col md="6" cols="12">
              <b-card no-body>
                <b-card-header>
                  <b-card-title>{{$t('Users')}}</b-card-title>
                  <b-button
                    variant="outline-success"
                    size="sm"
                    @click="$bvModal.show('addUserModal')"
                  >
                  <feather-icon
                    icon="UserPlusIcon"
                    size="13"
                    class="cursor-pointer"
                  />
                  </b-button>
                </b-card-header>
                <b-card-body>
                  <template v-for="(user, i) in users">
                    <dl class="row" :key="i">
                      <dt class="col-sm-8 font-weight-bolder">
                        {{user.name}}<br>
                        <small>{{user.email}}</small><br>
                        <small>{{user.mobile}}</small>
                      </dt>
                      <dd class="col-sm-2">
                        {{user.role.name}}
                      </dd>
                      <dd class="col-sm-2">
                        <b-button
                          variant="flat-danger"
                          size="sm"
                          @click="removeUser(user.id)"
                        >
                          <feather-icon 
                            icon="UserXIcon"
                            size="15"
                          />
                        </b-button>
                      </dd>
                    </dl>
                  </template>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
          </transition>
        </template>
    </b-overlay>

    <!-- Edit Owner Modal -->
    <b-modal
      id="ownerModal"
      modal-class="modal-primary"
      :title="$t('Edit Owner Details')"
      hide-footer
      centered
    >
      <b-form @submit.prevent="updateOwner">
        <!-- Name -->
        <b-form-group
          :label="$t('Name')"
          label-for="v-owner-name"
        >
          <b-form-input
            id="v-owner-name"
            type="text"
            v-model="owner.name"
          />
        </b-form-group>

        <!-- Mobile -->
        <b-form-group
          :label="$t('Mobile')"
          label-for="v-owner-mobile"
        >
          <b-form-input
            id="v-owner-mobile"
            type="text"
            v-model="owner.mobile"
          />
        </b-form-group>

        <!-- Email -->
        <b-form-group
          :label="$t('Email')"
          label-for="v-owner-email"
        >
          <b-form-input
            id="v-owner-email"
            type="email"
            v-model="owner.email"
          />
        </b-form-group>

        <!-- Password -->
        <b-form-group
          :label="$t('Password')"
          label-for="v-owner-password"
        >
          <b-form-input
            id="v-owner-password"
            type="text"
            v-model="owner.password"
          />
        </b-form-group>

        <!-- Submit -->
        <b-col cols="12" class="p-0 text-right">

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="ml-1"
          >
            <template v-if="!isLoadingSave">
              {{$t('Save')}}
            </template>
            <template v-if="isLoadingSave">
              {{$t('Saveing')}}...
              <b-spinner small />
            </template>
          </b-button>

        </b-col>
      </b-form>
    </b-modal>


    <!-- Add User Modal -->
    <b-modal
      id="addUserModal"
      modal-class="modal-primary"
      :title="$t('Invite User')"
      hide-footer
      centered
      no-close-on-backdrop
    >
      <b-form @submit.prevent="inviteUser">

        <b-col sm="6" offset-sm="3">
          <b-img src="https://cdn.mhd.sa/asset/images/icons/inviteation.png" fluid />
        </b-col>
        <p class="text-center mt-1 mb-1 text-success">{{$t('Invite your team to assist you to manage the store')}}</p>
        <!-- Email -->
        <b-form-group
          :label="$t('Email')"
          label-for="v-owner-email"
        >
          <b-form-input
            id="v-email"
            type="email"
            v-model="addUser.email"
          />
        </b-form-group>

        <!-- Role -->
        <b-form-group
          :label="$t('Role')"
          label-for="v-role"
        >
          <v-select
            v-model="addUser.role"
            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="roles"
          />
        </b-form-group>

        <!-- Submit -->
        <b-col cols="12" class="p-0 text-right">

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="ml-1"
          >
            <template v-if="!isLoadingSave">
              {{$t('Send')}}
            </template>
            <template v-if="isLoadingSave">
              {{$t('Sending')}}...
              <b-spinner small />
            </template>
          </b-button>

        </b-col>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import useJwt from '@/auth/useJwt'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  name: 'Users',
  data() {
    return {
      isLoading: false,
      isLoadingSave: false,
      owner: {},
      users: {},
      roles: {},
      addUser: {}
    }
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    getUsers(){
        this.isLoading = true
        useJwt.post('/users/get-all',{store_id: this.$store.state.store.id})
        .then((response) => {
          // console.log(response.data)
          this.owner = response.data.data.owner
          this.users = response.data.data.users
          this.roles = response.data.data.roles
          this.isLoading = false
        })
        .catch(response => {
          // console.log(response);
        });
    },
    updateOwner() {
      this.isLoadingSave = true;
      this.owner.store_id = this.$store.state.store.id

      useJwt.post('/users/update',this.owner)
      .then((response) => {
        // console.log(response.data);
        this.owner = response.data.data
        this.isLoadingSave = false
        this.$root.showToast('success', 'save', '')
        this.$bvModal.hide('ownerModal');
      })
      .catch(error => {
        this.isLoadingSave = false
        this.$root.showAlert('error', error.response.data.message)
      });
    },
    removeUser(id) {
      // this.isLoadingSave = true;
      // this.owner.store_id = this.$store.state.store.id

      useJwt.post('/users/remove-user',{id: id, store_id: this.$store.state.store.id})
      .then((response) => {
        // console.log(response.data);
        this.users = response.data.data
        // this.isLoadingSave = false
        this.$root.showToast('success', 'save', '')
        // this.$bvModal.hide('ownerModal');
      })
      .catch(error => {
        this.isLoadingSave = false
        this.$root.showAlert('error', error.response.data.message)
      });
    },
    inviteUser() {
      this.isLoadingSave = true;
      this.addUser.store_id = this.$store.state.store.id

      useJwt.post('/users/invite-user',this.addUser)
      .then((response) => {
        // console.log(response.data);
        this.users = response.data.data
        this.isLoadingSave = false
        this.$root.showToast('success', 'sent', '')
        this.$bvModal.hide('addUserModal');
      })
      .catch(error => {
        this.isLoadingSave = false
        this.$root.showAlert('error', error.response.data.message)
      });
    },
  }
}
</script>
